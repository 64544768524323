// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../public/bg.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,700;1,400;1,700&display=swap);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "* {\n    box-sizing: border-box;\n    margin: 0;\n    padding: 0;\n}\n\n\n\nhtml { \n    background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center center fixed;\n    background-size: cover;\n    background-position: center;\n    font-family: 'Montserrat', sans-serif;\n  }\n\nbody {\n    display: flex;\n    justify-content: center;\n}\n\n.ql-align-center {\n    text-align: center;\n}\n\n.ql-align-right {\n    text-align: right;\n}\n\n.ql-align-justify {\n    text-align: justify;\n}\n\n#map {\n    flex: 1 1;\n    display: flex;\n    height: 500px;\n}\n", "",{"version":3,"sources":["webpack://./src/style.css"],"names":[],"mappings":"AAEA;IACI,sBAAsB;IACtB,SAAS;IACT,UAAU;AACd;;;;AAIA;IACI,iFAA+D;IAI/D,sBAAsB;IACtB,2BAA2B;IAC3B,qCAAqC;EACvC;;AAEF;IACI,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,SAAO;IACP,aAAa;IACb,aAAa;AACjB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,700;1,400;1,700&display=swap');\n\n* {\n    box-sizing: border-box;\n    margin: 0;\n    padding: 0;\n}\n\n\n\nhtml { \n    background: url(../public/bg.png) no-repeat center center fixed; \n    -webkit-background-size: cover;\n    -moz-background-size: cover;\n    -o-background-size: cover;\n    background-size: cover;\n    background-position: center;\n    font-family: 'Montserrat', sans-serif;\n  }\n\nbody {\n    display: flex;\n    justify-content: center;\n}\n\n.ql-align-center {\n    text-align: center;\n}\n\n.ql-align-right {\n    text-align: right;\n}\n\n.ql-align-justify {\n    text-align: justify;\n}\n\n#map {\n    flex: 1;\n    display: flex;\n    height: 500px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
